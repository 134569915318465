import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { Component } from "react"
import {Container} from 'react-bootstrap'
import "../css/header.css"
import "../css/main.css"
import { TimelineLite } from "gsap/all"
import TopLogo from "../images/logo-top.inline.svg"
import $ from "jquery"

const { detect } = require('detect-browser');
const browser = detect();

class Header extends Component {
  componentDidMount(){
    $("body").addClass(browser.name);
    let logoTl = new TimelineLite({ paused:true });
    if (window.matchMedia("(min-width: 576px)").matches) {
      logoTl
        .fromTo(".animatedHeader .header-logo", 1, { autoAlpha: 0 }, { autoAlpha: 1 }, 0.5)
        .fromTo(".animatedHeader .header-logo-decade-txt", 1, { x: 300, autoAlpha: 0 }, { x: 82, autoAlpha: 1 }, 0.8)
        .fromTo(".animatedHeader .header-logo-frontline-txt", 1, { x: 300, autoAlpha: 0 }, { x: 82, autoAlpha: 1 }, 1.5)
        // .staggerTo(".animatedHeader .header-logo-top svg .logo-text *", 0, { autoAlpha: 0 }, 0.03, "+=0.3")
        // .to(".animatedHeader .header-logo-decade-txt", 1, { fontSize: 24, x: 82, y: 0 }, 0.4)
        // .to(".animatedHeader .header-logo-frontline-txt", 1, { fontSize: 24, x: 82, y: 12 }, 0.4)
        // .set("#site-header .header-logo > a", {className:"+=anim-completed"})
        .call(setTimelineHeight)
        .fromTo(".animatedHeader .home-globe-bg", 1.2, { autoAlpha: 0 }, { autoAlpha: 1 }, 3)
        .fromTo(".animatedHeader .slide-article-list", 1, { autoAlpha: 0 }, { autoAlpha: 1 }, 3.8)
        .to(".animatedHeader .article-timeline", 1, { width: '100%' }, 4)
        .staggerFromTo(".animatedHeader .slide-article-year", 1, { xPercent: 30, autoAlpha: 0 }, { xPercent: 0, autoAlpha: 1 }, 0.2)
        .set(".animatedHeader .slide-article-img", {className:"+=imgLoaded"})
        .staggerTo(".animatedHeader .slide-article-img-cover", 1, {width: "100%"}, 0.1, "-=2")
        .play();

        $(".slide-article-img-cover, .slide-article-year a").click(function(){
          let logoTl2 = new TimelineLite({ paused:true });
          if(browser.name == "safari"){
            if (window.matchMedia("(min-width: 1501px)").matches) {
              logoTl2
                .staggerTo(".animatedHeader .header-logo-top svg .logo-text *", 0, { autoAlpha: 0 }, 0.03, "+=0.3")
                .to(".animatedHeader .header-logo-decade-txt", 1, { fontSize: 24, x: 82, y: 0 }, 0.4)
                .to(".animatedHeader .header-logo-frontline-txt", 1, { fontSize: 24, x: 82, y: 38 }, 0.4)
                .set("#site-header .header-logo > a", {className:"+=anim-completed"})
                .play();
            } else if (window.matchMedia("(min-width: 992px)").matches) {
              logoTl2
                .staggerTo(".animatedHeader .header-logo-top svg .logo-text *", 0, { autoAlpha: 0 }, 0.03, "+=0.3")
                .to(".animatedHeader .header-logo-decade-txt", 1, { fontSize: 24, x: 82, y: 0 }, 0.4)
                .to(".animatedHeader .header-logo-frontline-txt", 1, { fontSize: 24, x: 82, y: 24 }, 0.4)
                .set("#site-header .header-logo > a", {className:"+=anim-completed"})
                .play();
            } else if (window.matchMedia("(min-width: 768px)").matches) {
              logoTl2
                .staggerTo(".animatedHeader .header-logo-top svg .logo-text *", 0, { autoAlpha: 0 }, 0.03, "+=0.3")
                .to(".animatedHeader .header-logo-decade-txt", 1, { fontSize: 24, x: 82, y: 0 }, 0.4)
                .to(".animatedHeader .header-logo-frontline-txt", 1, { fontSize: 24, x: 82, y: 12 }, 0.4)
                .set("#site-header .header-logo > a", {className:"+=anim-completed"})
                .play();
            } else {
              logoTl2
                .staggerTo(".animatedHeader .header-logo-top svg .logo-text *", 0, { autoAlpha: 0 }, 0.03, "+=0.3")
                .to(".animatedHeader .header-logo-decade-txt", 1, { fontSize: 24, x: 82, y: 4 }, 0.4)
                .to(".animatedHeader .header-logo-frontline-txt", 1, { fontSize: 24, x: 82, y: 17 }, 0.4)
                .set("#site-header .header-logo > a", {className:"+=anim-completed"})
                .play();
            }
          } else {
            if (window.matchMedia("(min-width: 1501px)").matches) {
              logoTl2
                .staggerTo(".animatedHeader .header-logo-top svg .logo-text *", 0, { autoAlpha: 0 }, 0.03, "+=0.3")
                .to(".animatedHeader .header-logo-decade-txt", 1, { fontSize: 24, x: 82, y: 14 }, 0.4)
                .to(".animatedHeader .header-logo-frontline-txt", 1, { fontSize: 24, x: 82, y: 52 }, 0.4)
                .set("#site-header .header-logo > a", {className:"+=anim-completed"})
                .play();
            } else if (window.matchMedia("(min-width: 992px)").matches) {
              logoTl2
                .staggerTo(".animatedHeader .header-logo-top svg .logo-text *", 0, { autoAlpha: 0 }, 0.03, "+=0.3")
                .to(".animatedHeader .header-logo-decade-txt", 1, { fontSize: 24, x: 82, y: 4 }, 0.4)
                .to(".animatedHeader .header-logo-frontline-txt", 1, { fontSize: 24, x: 82, y: 30 }, 0.4)
                .set("#site-header .header-logo > a", {className:"+=anim-completed"})
                .play();
            } else if (window.matchMedia("(min-width: 768px)").matches) {
              logoTl2
                .staggerTo(".animatedHeader .header-logo-top svg .logo-text *", 0, { autoAlpha: 0 }, 0.03, "+=0.3")
                .to(".animatedHeader .header-logo-decade-txt", 1, { fontSize: 24, x: 82, y: 4 }, 0.4)
                .to(".animatedHeader .header-logo-frontline-txt", 1, { fontSize: 24, x: 82, y: 22 }, 0.4)
                .set("#site-header .header-logo > a", {className:"+=anim-completed"})
                .play();
            } else {
              logoTl2
                .staggerTo(".animatedHeader .header-logo-top svg .logo-text *", 0, { autoAlpha: 0 }, 0.03, "+=0.3")
                .to(".animatedHeader .header-logo-decade-txt", 1, { fontSize: 24, x: 82, y: 4 }, 0.4)
                .to(".animatedHeader .header-logo-frontline-txt", 1, { fontSize: 24, x: 82, y: 17 }, 0.4)
                .set("#site-header .header-logo > a", {className:"+=anim-completed"})
                .play();
            }
          }
        });

    } else {
      logoTl
        .fromTo(".animatedHeader .header-logo", 0.5, { autoAlpha: 0 }, { autoAlpha: 1 }, 0.5)
        .fromTo(".animatedHeader .header-logo-decade-txt", 0.5, { x: 200, autoAlpha: 0 }, { x: 42, autoAlpha: 1 }, 0.8)
        .fromTo(".animatedHeader .header-logo-frontline-txt", 0.5, { x: 200, autoAlpha: 0 }, { x: 42, autoAlpha: 1 }, 1.5)
        // .staggerTo(".animatedHeader .header-logo-top svg .logo-text *", 0, { autoAlpha: 0 }, 0.03, "+=0.3")
        // .to(".animatedHeader .header-logo-decade-txt", 0.5, { fontSize: 14, x: 42, y: 1 }, 0.4)
        // .to(".animatedHeader .header-logo-frontline-txt", 0.5, { fontSize: 14, x: 42, y: 7 }, 0.4)
        // .set("#site-header .header-logo > a", {className:"+=anim-completed"})
        .call(setTimelineHeight)
        .fromTo(".animatedHeader .home-globe-bg", 1.2, { autoAlpha: 0 }, { autoAlpha: 1 }, 3)
        .fromTo(".animatedHeader .slide-article-list", 1, { autoAlpha: 0 }, { autoAlpha: 1 }, 3.8)
        .to(".animatedHeader .article-timeline", 1, { width: '100%' }, 4)
        .staggerFromTo(".animatedHeader .slide-article-year", 1, { xPercent: 30, autoAlpha: 0 }, { xPercent: 0, autoAlpha: 1 }, 0.2)
        .set(".animatedHeader .slide-article-img", {className:"+=imgLoaded"})
        .staggerTo(".animatedHeader .slide-article-img-cover", 1, {width: "100%"}, 0.1, "-=2")
        .play();

        $(".slide-article-img-cover, .slide-article-year a").click(function(){
          let logoTl3 = new TimelineLite({ paused:true });
          if(browser.name == "safari"){
            logoTl3
              .staggerTo(".animatedHeader .header-logo-top svg .logo-text *", 0, { autoAlpha: 0 }, 0.03, "+=0.3")
              .to(".animatedHeader .header-logo-decade-txt", 0.5, { fontSize: 14, x: 42, y: 1 }, 0.4)
              .to(".animatedHeader .header-logo-frontline-txt", 0.5, { fontSize: 14, x: 42, y: 7 }, 0.4)
              .set("#site-header .header-logo > a", {className:"+=anim-completed"})
              .play();
          } else {
            logoTl3
              .staggerTo(".animatedHeader .header-logo-top svg .logo-text *", 0, { autoAlpha: 0 }, 0.03, "+=0.3")
              .to(".animatedHeader .header-logo-decade-txt", 0.5, { fontSize: 14, x: 42, y: 2 }, 0.4)
              .to(".animatedHeader .header-logo-frontline-txt", 0.5, { fontSize: 14, x: 42, y: 8 }, 0.4)
              .set("#site-header .header-logo > a", {className:"+=anim-completed"})
              .play();
          }
        });

    }
      
    

    function setTimelineHeight(){
      let windowHeight = $(window).height();
      let headerHeight = $("header").outerHeight();
      let setTimelineHeight = windowHeight - headerHeight;
      $(".slide-article-list .swiper-container").height(setTimelineHeight);
      $(".slide-article-list").css("margin-top", headerHeight);
    }
  }

  render(){
    return (
      <header id="site-header">
        <Container>
          <div className="header-logo">
            <Link
              to="/"
            >
              <span className="header-logo-top">
                <TopLogo />
              </span>
              <span className="header-logo-decade-txt">DECADES OF FUELING</span>
              <span className="header-logo-frontline-txt">THE FRONTLINES</span>
            </Link>
          </div>
        </Container>
      </header>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
